//
// user-variables.scss
// Use this to overwrite Bootstrap and Block variables
//
// Example of a variable override to change Block primary color
// Remove the "//" to comment it in and see it in action!
// $primary: #000;

$primary: #007cb2;

.hero {
  background: url(../images/apr2020design/img/hero.jpg) no-repeat center top;
  color: #ffffff;

  h1 {
    color: #ffffff;
  }
}

.module-pic img {
  max-height: 200px;
}

.content {
  a {
    text-decoration: underline;
  }
  a.btn,
  a.badge,
  a.text-reset,
  .pagination a.page-link{
    text-decoration: none;
  }
}

.list-group-item-light {
  &.active {
    a {
        color: #ffffff;
    }
  }
}

.page-header .breadcrumb > li + li:before {
  color: inherit;
  opacity: 0.5;
  font-family: 'boxicons';
  font-weight: 900;
  content: "\ea50";
  padding: 0 2px 0 0;
  vertical-align: bottom;
}

/* Blockquote Inspired by: http://css-tricks.com/snippets/css/simple-and-nice-blockquote-styling/ */
.sforum-post blockquote {
  margin: 10px 0 10px 10px;
  padding: 8px 8px 8px 8px;
  background-color: #F2F2F2;
  color: #555;
  border-left: 10px solid #CCC;
  font-size: 14px !important;
  border-radius: 5px;
}
.sforum-post blockquote p:first-child { margin-top: 0px; }
.sforum-post blockquote p:last-child  { margin-bottom: 0px; }
.sforum-post blockquote p:first-child:before {
  content: open-quote;
  quotes:"\201C""\201D";
  color: #CCC;
  font-size: 48px;
  line-height:.1em;
  position: absolute;
  margin-left: -3px;
  margin-top: 3px;
}