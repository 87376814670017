// Navbar

.navbar {
    padding: 0.5rem;
    background-color: var(--#{$prefix}body-bg);
    box-shadow: $box-shadow-sm;

    @media (max-width: 990px) {
        padding: 0.75rem 0.5rem;
    }
    .navbar-brand {
        padding-top: 0.2rem;
        padding-bottom: 1rem;
    }
    .navbar-toggler {
        border: 2px solid var(--#{$prefix}dark);
        padding: 0.25rem 0.5rem;
        font-size: 1.5rem;
        color: var(--#{$prefix}dark);
        &:focus {
            outline: 0rem;
            box-shadow: none;
        }
    }
    .dropdown {
        .dropdown-toggle {
            &:after {
                color: var(--#{$prefix}gray-500);
                font-family: "bootstrap-icons" !important;
                content: "\F282";
                line-height: 1.5rem;
                margin-left: 0.625rem;
            }
            &:after:hover {
                transform: rotate(180deg);
            }
        }
    }
    .dropdown-menu {
        top: 100%;
        color: var(--#{$prefix}gray-800);
        border: none;
        min-width: 12rem;
        box-shadow: var(--#{$prefix}box-shadow);
        border-radius: 0.5rem;
        margin-top: 0rem;

        @media (max-width: 992px) {
            box-shadow: none;
            min-width: 0 !important;
            background-color: transparent;
        }
        .dropdown-submenu {
            a {
                &::after {
                    transform: rotate(-90deg);
                    position: absolute;
                    right: 1rem;
                }
            }
        }
        .dropdown-submenu.dropend {
            .dropdown-menu {
                top: -18px;
                right: auto;
                left: 100%;
                box-shadow: var(--#{$prefix}box-shadow);
                min-width: 12rem;
                border-radius: 0.5rem;
                @media (max-width: 992px) {
                    box-shadow: none;
                }
            }
        }
    }
    .dropdown-fullwidth {
        position: static;
        .dropdown-menu {
            left: 0 !important;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            max-width: 1140px;

            right: 0 !important;
            top: 100%;
            transform: none !important;
        }
    }
    .navbar-nav {
        .dropdown {
            @media (min-width: 990px) {
                > .dropdown-menu {
                    left: 50%;
                    transform: translate(-50%, 10px);
                }
            }
        }
        .dropend {
            > .dropdown-menu {
                transform: translateY(10px);
            }
        }
        .nav-item {
            .nav-link {
                font-size: 0.9375rem;
                color: var(--#{$prefix}gray-500);
                font-weight: 500;
                padding: 0.5rem 1rem;
                border-radius: 0.25rem;

                @media (max-width: 992px) {
                    padding: 0.75rem 0rem !important;
                    border-bottom: 1px solid var(--#{$prefix}gray-200);
                    border-radius: 0rem;
                    &:hover {
                        color: var(--#{$prefix}dark);
                        background-color: transparent !important;
                    }
                }
                &:focus-visible {
                    box-shadow: none;
                }
                &:hover {
                    color: var(--#{$prefix}primary);
                }
                &.active {
                    color: var(--#{$prefix}primary);
                }
            }
        }
    }
}

// Responsive breakpoints
@media (min-width: 1200px) {
    .navbar {
        .dropdown-menu.show {
            display: none;
        }
        .dropdown-menu {
            display: block;
            visibility: hidden;
            opacity: 0;
            -webkit-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            transform: translate(-50%);
        }

        .dropdown {
            &:hover {
                > .dropdown-menu {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        .dropdown-submenu {
            &:hover {
                > .dropdown-menu {
                    transform: translateY(10px);
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

// navbar dark

.navbar-dark {
    transition: all 0.3s ease-in-out;

    .navbar-brand {
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
    }

    .navbar-nav {
        .nav-link {
            color: var(--#{$prefix}gray-300) !important;

            @media (max-width: 990px) {
                color: var(--#{$prefix}gray-500) !important;
            }
        }
        .dropdown {
            .dropdown-toggle {
                &:after {
                    color: var(--#{$prefix}gray-300);
                    @media (max-width: 990px) {
                        color: var(--#{$prefix}gray-500) !important;
                    }
                }
            }
        }
    }
}

// navbar light
.navbar-light {
    border-bottom: 1px solid var(--#{$prefix}gray-100);
    .navbar-nav {
        .nav-item {
            .nav-link {
                color: var(--#{$prefix}gray-500);
                font-weight: 500;

                &:hover,
                &.active {
                    color: var(--#{$prefix}gray-800);
                    background-color: var(--#{$prefix}gray-100);
                }
            }
        }
    }
}

// navbar transparent
.navbar-transparent {
    background-color: transparent;
    position: absolute;
    width: 100%;
    z-index: 1009;
    left: 0;
    right: 0;
    box-shadow: none;
    .navbar-toggler {
        border-color: $white;
        color: $white;
    }
}

@media (max-width: 990px) {
    .navbar-transparent.navbar-clone {
        .navbar-brand {
            filter: brightness(0) invert(1);
        }
        .navbar-toggler {
            border-color: $dark;
            color: $dark;
        }
    }
    .navbar-transparent.navbar-stick {
        .navbar-brand {
            filter: brightness(1) invert(0);
        }
    }
}

// navbar clone
.navbar-clone {
    position: fixed !important;
    z-index: 1008;
    top: 0;
    left: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    box-shadow: none;
}

// navbar stick
.navbar-stick {
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    border-bottom: 1px solid var(--#{$prefix}gray-100);
    &:not(.navbar-dark) {
        box-shadow: $box-shadow-sm;
        background: var(--#{$prefix}body-bg);
        &.navbar-brand {
            -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
        }
    }
}

// offcanvas
.offcanvas.show:not(.hiding),
.offcanvas.showing {
    transform: none;
}

.offcanvas {
    height: 100vh;
}
